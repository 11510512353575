body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.center {
  position: absolute;
  left: 50%;
  top: 50%;
  -moz-transform: translate(-50%, -50%); /* Firefox */
  -ms-transform: translate(-50%, -50%);  /* IE 9 */
  -webkit-transform: translate(-50%, -50%); /* Safari and Chrome*/
  -o-transform: translate(-50%, -50%); /* Opera */
  transform: translate(-50%, -50%);
}

.emoji-background {
  display: inline-block;
  text-align: center;
  width: 80px;
  height: 80px;
  font-size: 40px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.04);
  line-height: 80px;
}

.book {
  justify-self: center;
}