html {
  scrollbar-width: none;  /* Firefox */
}

body {
  margin: 0px;
  padding: 0px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu {
  display: flex;
  align-items: center;   
}
